.Badge__wrapper {
  overflow-x: scroll;

  .Badge__table {
    width: 100%;
    border-collapse: collapse;
  }
}

.Badges__upload {
  width: 100%;
  display: flex;
  flex-direction: column;
}
