/* light mode */
$color__primary: #003f86;
$color__background: #fff;

/* color */
$color__title: #303030;
$color__text: #c6c6c6;

/* light mode */
$light__color__primary: #00509d;
$light__color__secondary: #0b5ab3;
$light__color__background: #fefefe;
$light__color__title: #1e2022;
$light__color__text: #677788;

/* dark mode */
$dark__color__primary: #002db3;
$dark__color__background: #d2d2d6;
$dark__color__title: #ffffff;
$dark__color__text: #515265;
