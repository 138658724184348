.FormCreateNote {
  padding: 2rem 2rem 1rem 2rem;

  &__contentBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  &__slug {
    position: relative;
    margin-bottom: 1.5rem;

    .Field {
      margin-bottom: 0;
    }
  }
}

.editor-class {
  border: 1px solid #eeeeee;
}
