@import '../../Styles/Variables/varProject';

.Sidebar__overlay {
  display: none;

  @media (max-width: 1199px) {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: rgba($color: #132144, $alpha: 0.25);
  }
}

.Sidebar {
  position: fixed;
  width: 260px;
  height: 100%;
  z-index: 10;
  background-color: $light__color__primary;
  border-left: none;
  border-top: none;
  border-bottom: none;
  transition: left 500ms;

  &__header {
    padding: 1rem 1.375rem;

    &-logo {
      width: 80px;
    }
  }

  &__body {
    position: relative;

    &-list {
      display: flex;
      flex-direction: column;
    }
  }
}

/* animation */
.Sidebar--entering {
  left: -100%;
}

.Sidebar--entered {
  left: 0;
}

.Sidebar--exiting {
  left: -100%;
}

.Sidebar--exited {
  left: -100%;
}
