@import '../../Styles/Variables/varProject';

/* variant btn */
.btn-primary {
  border: 1px solid $light__color__primary;
  background-color: $light__color__primary;
  color: #ffffff;

  &:hover,
  &:active {
    background-color: $light__color__secondary;
  }

  &:disabled {
    opacity: 0.5;
    background-color: $light__color__primary;
    cursor: not-allowed;
  }
}

.btn-secondary {
  background-color: $light__color__secondary;
  color: #ffffff;

  &:disabled {
    opacity: 0.5;
    background-color: $light__color__secondary;
    cursor: not-allowed;
  }
}

.btn-outline {
  border: 1px solid $light__color__primary;
  color: $light__color__primary;

  &:hover,
  &:active {
    background-color: $light__color__primary;
    color: #ffffff;
  }
}

/* size btn */
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
  font-weight: 400;
  line-height: 1.5;
  cursor: pointer;
}

.btn-md {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  font-weight: 400;
  line-height: 1.5;
  cursor: pointer;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
  font-weight: 400;
  line-height: 1.5;
  cursor: pointer;
}

@media (max-width: 576px) {
  [class*='btn'] {
    width: 100%;
  }
}
