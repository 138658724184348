.OverviewUser {
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba($color: #8c98a4, $alpha: 0.1);

  .OverviewUser__cover {
    height: 150px;

    img {
      flex: 1;
      object-fit: cover;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  .OverviewUser__contentProfile {
    margin: -4rem auto 0 auto;

    .OverviewUser__contentProfile__avatar {
      width: 100px;
      height: 100px;
    }
  }

  .OverviewUser__footer {
    border-top: 1px solid rgba(231, 234, 243, 0.7);

    .btn--secondary {
      background-color: #ffffff;
      color: #e0e0e0;

      .OverviewUser__footer__titleBtn {
        font-weight: bold;
        font-size: 1rem;
        letter-spacing: 0.5px;
        white-space: nowrap;
        text-align: center;
      }

      &:hover {
        background-color: rgba($color: #066ac9, $alpha: 0.1);
        color: #066ac9;
      }
    }

    @media (max-width: 576px) {
      flex-direction: column;
    }
  }
}
