@import '../../../Styles/Variables/varProject';

.Step {
  flex: 1;
  padding-right: 1rem;

  .Step__content {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    @media (max-width: 576px) {
      overflow: visible;

      &::after {
        content: '';
        position: absolute;
        height: 30px;
        top: 35px;
        left: 15px;
        border: 0.5px solid rgba($color: $color__text, $alpha: 0.4);
      }
    }

    .Step__circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      border: 1px solid rgba($color: $color__text, $alpha: 0.2);
      background-color: rgba($color: $color__text, $alpha: 0.2);
      border-radius: 50%;

      .Step__value {
        font-size: 1rem;
        font-weight: bold;
        color: rgba($color: $color__title, $alpha: 0.8);
      }
    }

    .Step__circle.active {
      background-color: $color__primary;

      .Step__value {
        color: $color__background;
      }
    }

    .Step__name {
      font-size: 1rem;
      font-family: 'Roboto', sans-serif;
      color: $color__title;
      white-space: nowrap;

      &::after {
        content: '';
        position: absolute;
        top: 55%;
        width: calc(100% - 0.75rem);
        height: 1px;
        margin-left: 14px;
        border: 0.5px solid rgba($color: #c6c6c6, $alpha: 0.4);
        vertical-align: middle;
        transform: translateY(-55%);

        @media (max-width: 576px) {
          display: none;
        }
      }
    }

    .Step__name.active {
      color: $color__primary;
    }
  }

  .Step__content--lastChild::after {
    content: '' !important;
    display: none;
  }

  &:last-child {
    flex: 0;
    padding: 0;
  }

  @media (max-width: 575px) {
    padding: 1rem 0 !important;
  }
}
