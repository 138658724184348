.Home {
  width: 100%;
  height: auto;

  .Home__main {
    margin-left: 260px;

    @media (max-width: 1199px) {
      margin-left: 0;
    }

    @media (min-width: 992px) {
      height: 100%;
    }
  }

  @media (min-width: 992px) {
    height: 100%;
  }
}
