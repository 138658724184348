@import '../../Styles/Variables/varProject';

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid rgba($color: $light__color__text, $alpha: 0.2);

  &__profile {
    display: flex;
    align-items: center;

    &-image {
      width: 35px;
    }

    &-text {
      font-weight: 600;
      font-size: 1rem;
      color: $light__color__title;
      margin-left: 0.675rem;
    }
  }

  &__option {
    display: flex;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 35px;
      height: 35px;
      margin: 0 0.257rem;
      border-radius: 50%;

      .Header__item-icon {
        font-size: 20px;
        color: rgba($color: $light__color__text, $alpha: 0.6);
      }

      &:hover {
        background-color: rgba($color: $light__color__primary, $alpha: 0.2);
      }

      &:hover .Header__item-icon {
        color: $light__color__primary;
      }

      &:first-child {
        display: none;

        @media (max-width: 1199px) {
          display: flex;
        }
      }
    }
  }

  @media (max-width: 576px) {
    padding: 1rem !important;
  }
}
