@import '../../Styles/Variables/varProject';

.Footer {
  margin-top: auto;

  .Footer__text {
    font-size: 0.875rem;
    font-weight: 500;
    color: $color__text;
  }
}
