.Hero {
  display: flex;

  &__image {
    object-fit: cover;

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    height: 240px;
  }

  @media (max-width: 576px) {
    height: 140px;
  }
}
