html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  outline: none;
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

svg {
  vertical-align: middle;
}
