@import '../Variables/variables';

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
