.SubListItem {
  display: flex;
  flex-direction: column;
  position: relative;

  &__item {
    display: flex;
    align-items: center;
    padding: 0.5rem 4rem;

    &-icon {
      width: 6px;
      height: 6px;
      background-color: rgba($color: #b0bec5, $alpha: 0.8);
      border-color: rgba($color: #b0bec5, $alpha: 0.8);
      border-radius: 50%;

      &:hover {
        color: #ffffff;
      }
    }

    &-text {
      color: rgba($color: #ffffff, $alpha: 0.6);
      font-size: 0.875rem;
      margin-left: 0.5rem;

      &:hover {
        color: #ffffff;
      }
    }
  }

  .active {
    color: #ffffff !important;

    & > .SubListItem__item-icon {
      border-radius: 50%;
      background-color: #ffffff !important;
      border-color: #ffffff !important;
    }
  }
}
