@import '../../Styles/Variables/varProject';

.UploadPrivacyPolicy__navigation {
  display: flex;
  margin: 0 -15px;
}

.UploadPrivacyPolicy__list {
  display: flex;
  flex: 1;
  border-bottom: 1px solid #eeeeee;
}

.UploadPrivacyPolicy__list-item {
  padding: 0 1rem 0.5rem 1rem;
  font-weight: 400;
  cursor: pointer;
  color: #677788;
  font-size: 0.875rem;
}

.UploadPrivacyPolicy {
  width: 100%;
  margin-top: 2rem;
}

.UploadPrivacyPolicy__content {
  display: flex;
  align-items: center;
  padding: 1rem 0 1.25rem 0;
}

.UploadPrivacyPolicy__content svg {
  color: $color__primary;
}

.UploadPrivacyPolicy__content p {
  margin-left: 0.5rem;
  font-weight: 600;
  color: #1e2022;
}

.UploadPrivacyPolicy__content-watchPrivacyPolicy {
  display: flex;
  align-items: center;
  padding: 2rem 0 1.25rem 0;
  cursor: pointer;
}

.UploadPrivacyPolicy__content-watchPrivacyPolicy svg {
  color: $color__primary;
}

.UploadPrivacyPolicy__content-watchPrivacyPolicy p {
  margin-left: 0.5rem;
  font-weight: 600;
  color: #1e2022;
}

.UploadPrivacyPolicy__btn {
  width: 100%;
}
