@import '../../Styles/Variables/varProject';

.Login {
  height: auto;

  &__row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__container {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__hero {
    display: flex;
    flex: 1;

    @media (max-width: 991px) {
      flex: 1 1 100%;
    }
  }

  &__sign {
    display: flex;
    flex-direction: column;
    width: 420px;
    margin: 0 auto;

    &-content {
      padding: 4rem 1.5rem;

      @media (max-width: 576px) {
        padding: 3rem 1rem;
      }
    }

    @media (max-width: 991px) {
      width: 100%;
      max-width: 720px;
    }
  }

  &__content-title {
    text-align: center;
    font-size: 2rem;
    color: $light__color__title;
    font-weight: bold;
    line-height: 1.25;

    @media (max-width: 991px) {
      text-align: left;
    }

    @media (max-width: 576px) {
      text-align: center;
    }
  }

  &__copyright {
    color: $light__color__text;
    font-weight: 400;
    line-height: 1.5;
    font-size: 0.875rem;
    max-width: 380px;
    text-align: center;
    margin: auto auto 1.25rem auto;

    @media (max-width: 991px) {
      display: none;
    }
  }

  /* loader */
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  @media (min-width: 992px) {
    height: 100%;
  }
}
