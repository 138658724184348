@import '../../Styles/Variables/varProject';

.ProfileUser__label {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 400;
  color: #1e2022;

  @media (max-width: 576px) {
    margin-bottom: 0.6125rem;
  }
}

.ProfileUser__avatar {
  width: 80px;

  @media (max-width: 576px) {
    margin-top: 0.6125rem;
  }
}

.ProfileUser__field > * {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.ProfileUser__field > .Field > label {
  flex-basis: 25%;
  padding: 0 15px;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  color: #1e2022;

  @media (max-width: 576px) {
    flex-basis: 100%;
  }
}

.ProfileUser__field > .Field > div {
  flex-basis: 75%;
  padding: 0 15px;

  @media (max-width: 576px) {
    flex-basis: 100%;
    margin-top: 0.6125rem;
  }
}

.ProfileUser__footer {
  border-top: 1px solid rgba(231, 234, 243, 0.7);

  .ProfileUser__btn {
    padding: 0.625rem 1rem;
  }

  .ProfileUser__footer__text {
    font-weight: bold;
    font-size: 0.875rem;
    letter-spacing: 0.5px;
    white-space: nowrap;
    text-align: center;
  }

  svg {
    vertical-align: middle;
    font-size: 1.125rem;
  }
}
