.Dashboard {
  padding: 1.5rem 2rem;

  &__data {
    display: flex;
    margin-bottom: 1rem;
  }

  &__chart {
    margin-top: 1rem;
  }
}
