@import '../../Styles/Variables/varProject';

.Searchbar__icon {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 20px !important;
  color: $color__title;
}

.Searchbar__input {
  width: 100%;
  border: none;
  border-bottom: 1px solid $color__text;
  padding: 0.75rem 0 0.75rem 2rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    border-bottom: 1px solid $color__primary;
  }
}

.Searchbar__btn {
  position: absolute;
  right: 0;
  padding: 0.5rem 0.75rem !important;
  font-size: 0.875rem !important;
}
