@import '../..//Styles/Variables/varProject';

.InformationUser__field > * {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.InformationUser__field > .Field > label {
  flex-basis: 25%;
  padding: 0 15px;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 600;

  @media (max-width: 576px) {
    flex-basis: 100%;
  }
}

.InformationUser__field > .Field > div {
  flex-basis: 75%;
  padding: 0 15px;

  @media (max-width: 576px) {
    flex-basis: 100%;
    margin-top: 0.6125rem;
  }
}

.InformationUser__field__phone > .Field > div {
  display: flex;
  flex-direction: column;
  flex-basis: 75%;
  padding: 0 15px;

  @media (max-width: 576px) {
    flex-basis: 100%;
    margin-top: 0.6125rem;
  }
}

.InformationUser__field > .Field > div > input[type='date'] {
  color: #bdbdbd;
}

.InformationUser__input {
  padding-left: 5.5rem;
  display: block;
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 5.5rem;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #eeeeee;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border: 1px solid $light__color__primary;
    box-shadow: 0 0px 0 4px rgba($color: $light__color__primary, $alpha: 0.2);
  }

  &::placeholder {
    color: #bdbdbd;
    font-size: 1rem;
    font-weight: 400;
  }
}

.InformationUser__prefixPhone {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 14px;
  height: 41px;
  padding: 0 0.75rem;
  border-right: 1px solid #eeeeee;
}

.InformationUser__icon {
  flex-shrink: 0;
  width: 20px;
}

.InformationUser__text {
  white-space: nowrap;
  font-size: 0.875rem;
  margin-left: 6px;
}

.InformationUser__error {
  width: 100%;
  font-size: 0.875rem;
  font-weight: 500;
  color: #f44336;
  font-family: 'Roboto', sans-serif;
  margin-top: 0.5rem;
}

.InformationUser__footer {
  border-top: 1px solid rgba(231, 234, 243, 0.7);

  .btn--secondary {
    background-color: #ffffff;
    color: rgba($color: $color__text, $alpha: 0.7);

    &:hover {
      padding: 0.625rem 1rem;
      background-color: rgba($color: $color__primary, $alpha: 0.1);
      border: 1px solid rgba($color: $color__primary, $alpha: 0.1);
      color: #066ac9;
    }

    @media (max-width: 576px) {
      margin-bottom: 8px;
    }
  }

  .InformationUser__btn {
    padding: 0.625rem 1rem;
  }

  .InformationUser__footer__text {
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 0.5px;
    white-space: nowrap;
    text-align: center;
  }

  svg {
    vertical-align: middle;
    font-size: 1.125rem;
  }

  @media (max-width: 576px) {
    flex-direction: column;
  }
}
