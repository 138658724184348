.Backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.3);
  z-index: 6000;
  transition: opacity 500ms ease-in;
}

.Modal {
  width: 100%;
  max-width: 500px;
  background: #fffffe;
  border-radius: 6px;
  opacity: 1;
  margin: 1.75rem auto;
}

@media (max-width: 576px) {
  .Modal {
    max-width: calc(100% - 30px);
  }
}

.Modal__header-navigation {
  padding: 0.875rem 1rem 0.5rem 1rem;
  border-bottom: 0;
}

.Modal__header-navigation h1 {
  font-size: 1.25rem;
  text-align: left;
  font-weight: 500;
}

@media (max-width: 576px) {
  .Modal__header-navigation h1 {
    font-size: 1.125rem;
  }
}

.Modal__header {
  padding: 0.875rem 1rem;
  border-bottom: 1px solid #eeeeee;
}

.Modal__header h1 {
  font-size: 1.25rem;
  text-align: left;
  font-weight: 500;
}

@media (max-width: 576px) {
  .Modal__header h1 {
    font-size: 1.125rem;
  }
}

.Modal__header__icon {
  vertical-align: middle;
  color: #bdbdbd;
  cursor: pointer;
}

.Modal__body {
  padding: 1rem;
}

/* animation */
.modal--entering {
  opacity: 0;
}

.modal--entered {
  opacity: 1;
}

.modal--exiting {
  opacity: 0;
}

.modal--exited {
  opacity: 0;
}
