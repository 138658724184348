@import '../../Styles/Variables/varProject';

.Dropdown {
  flex-direction: column;
  position: absolute;
  min-width: 360px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0 4px 6px rgba($color: $color__title, $alpha: 0.2);
  border-radius: 6px;
  margin-top: 0.5rem;
  z-index: 10;
  transition: all 500ms;

  .Dropdown__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba($color: $color__text, $alpha: 0.3);
  }

  .Dropdown__title {
    font-size: 0.875rem;
    color: $color__title;
    font-weight: 600;
  }

  .Dropdown__box {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .Dropdown__icon {
      font-size: 20px;
      color: $color__text;

      &:hover {
        color: $color__primary;
      }
    }
  }

  .Dropdown__body {
    flex: 1;
    padding: 1.25rem 1rem;
  }

  @media (min-width: 577px) {
    right: 0;
  }

  @media (max-width: 576px) {
    min-width: 0;
    left: 0;
  }
}

.Dropdown--select {
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 0.75rem;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  background-color: #ffffff;
  z-index: 10;
  transition: all 500ms;

  .Dropdown__list {
    display: flex;
    flex-direction: column;
    max-height: 220px;
    overflow-y: scroll;

    .Dropdown__item {
      padding: 0.75rem 1rem;
      font-size: 0.875rem;
      color: #1e2022;

      &:hover:first-child {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
      }

      &:hover {
        background-color: rgba($color: $color__primary, $alpha: 0.15);
        color: $color__primary;
        font-weight: 600;
      }
    }
  }
}

/* animation */
.dropdown--entering {
  top: -100%;
  opacity: 0;
}

.dropdown--entered {
  top: 100%;
  opacity: 1;
}

.dropdown--exiting {
  top: -100%;
  opacity: 0;
}

.dropdown--exited {
  top: -100%;
  opacity: 0;
}

/* animation select */
.dropdown--select--entering {
  top: -100%;
  opacity: 0;
}

.dropdown--select--entered {
  top: 100%;
  opacity: 1;
}

.dropdown--select--exiting {
  top: -100%;
  opacity: 0;
  pointer-events: none;
}

.dropdown--select--exited {
  top: -100%;
  opacity: 0;
  pointer-events: none;
}
