@import '../../Styles/Variables/varProject';

.LoginForm {
  margin-top: 1.5rem;

  &__icon {
    position: absolute;
    top: 8px;
    right: 15px;
    cursor: pointer;

    svg {
      font-size: 22px;
      color: $light__color__primary;
    }
  }

  &__btn {
    width: 100%;
  }
}
