@import '../../../Styles/Variables/varProject';

.OverviewInfo {
  display: flex;
  align-items: center;
  justify-content: center;

  .OverviewInfo__label {
    font-size: 1rem;
    font-weight: 600;
    color: $color__title;
  }

  .OverviewInfo__value {
    font-size: 1rem;
    font-weight: 300;
    margin-left: 1.75rem;
    color: $color__text;

    @media (max-width: 576px) {
      margin-left: 0;
      margin-top: 4px;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 16px;
  }
}
