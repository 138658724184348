@import '../../Styles/Variables/varProject';

.TableList {
  max-height: 420px;
  overflow-y: scroll;

  .TableList__image {
    width: 200px;
  }

  .TableList__text {
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    color: $color--title;
    font-weight: 400;
    line-height: 1.5;
    white-space: nowrap;
    text-align: center;
  }

  .isDelay {
    color: #222;
    background-color: #d32f2f;
  }

  .isAutomatic {
    color: #222;
    background-color: #c2d563;
  }

  .TableList__content {
    flex-wrap: nowrap;
    padding: 0 1rem;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .TableList__column {
      padding: 0.75rem 1rem;
      min-width: 160px;

      .TableList__box {
        border: 1px solid rgba($color: $color__text, $alpha: 0.25);
        border-radius: 4px;
        width: max-content;
        padding: 0.5rem;
        cursor: pointer;
        transition: all 600ms;

        svg {
          font-size: 18px;
          color: $color__text;
        }

        p {
          font-size: 0.875rem;
          margin-left: 0.5rem;
          font-weight: 400;
          color: $color__title;
        }

        &:hover {
          box-shadow: 0 3px 6px -2px rgba($color: $color__text, $alpha: 0.25);
        }

        &:hover > svg {
          color: $color__primary;
        }

        &:hover > p {
          color: $color__primary;
        }
      }

      .TableList__box--delete {
        border: 1px solid rgba($color: #d32f2f, $alpha: 0.25);
        border-radius: 4px;
        width: max-content;
        padding: 0.5rem;
        cursor: pointer;
        transition: all 600ms;

        svg {
          font-size: 18px;
          color: #d32f2f;
        }

        p {
          font-size: 0.875rem;
          margin-left: 0.5rem;
          font-weight: 400;
          color: #d32f2f;
        }

        &:hover {
          box-shadow: 0 3px 6px -2px rgba($color: $color__text, $alpha: 0.4);
        }
      }

      .TableList__box--active {
        border: 1px solid rgba($color: #2e7d32, $alpha: 0.25);
        border-radius: 4px;
        width: max-content;
        padding: 0.5rem;
        cursor: pointer;
        transition: all 600ms;

        svg {
          font-size: 18px;
          color: #2e7d32;
        }

        p {
          font-size: 0.875rem;
          margin-left: 0.5rem;
          font-weight: 400;
          color: #2e7d32;
        }

        &:hover {
          box-shadow: 0 3px 6px -2px rgba($color: $color__text, $alpha: 0.4);
        }
      }
    }

    .TableList__column--info {
      display: flex;
      justify-content: center;
    }
  }
}

.disabled {
  background-color: #eeeeee;
}

.Table-deleteBtn {
  background-color: #c62828 !important;
  border: 1px solid #c62828 !important;
}
