@import '../../Styles/Variables/varProject';

.NotFound {
  height: auto;

  .NotFound__header {
    padding: 0.875rem 1.5rem;
    margin: 0 auto;

    .NotFound__header-image {
      width: 90px;
    }
  }

  .NotFound__content {
    padding: 2.5rem 0;

    .NotFound__col {
      margin: 1rem auto;

      .NotFound__title {
        font-size: 3.25rem;
        font-weight: bold;
        color: $light__color__title;
        line-height: 1;
        margin-bottom: 1rem;

        @media (max-width: 991px) {
          text-align: center;
          font-size: calc(1.5rem + 3vw);
        }

        @media (max-width: 576px) {
          font-size: 2rem;
          text-align: center;
        }
      }

      .NotFound__description {
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.5;
        color: $light__color__text;
        margin-bottom: 1.5rem;

        @media (max-width: 991px) {
          font-size: calc(1rem + 0.45vw);
          text-align: center;
        }

        @media (max-width: 576px) {
          font-size: 1rem;
          text-align: center;
        }
      }

      .NotFound__btn {
        display: flex;
        justify-content: flex-start;

        @media (max-width: 991px) {
          justify-content: center;
        }
      }

      &:first-child {
        @media (max-width: 991px) {
          order: 2;
        }
      }
    }
  }

  @media (min-width: 992px) {
    min-height: 100vh;
  }
}
