@import '../../../Styles/Variables/varProject';

.ListItem {
  position: relative;

  &__link {
    display: flex;
    align-items: center;
    padding: 0.425rem 1.375rem 0.425rem 1.375rem;
    margin: 0.425rem 0 0.425rem 0;

    &-name {
      color: rgba($color: $light__color__background, $alpha: 0.6);
      font-size: 1rem;
      font-weight: 400;
      line-height: 1;
      flex: 1;
      margin-left: 1rem;
    }
  }

  &__icon {
    vertical-align: middle;
    color: rgba($color: $light__color__background, $alpha: 0.6);
    font-size: 20px;
  }

  &__sublist {
    position: relative;
    overflow: hidden;
    height: 0;
    transition: height 500ms;
  }

  .active > .ListItem__link-name {
    color: #ffffff;
  }

  .active > .ListItem__icon {
    color: #ffffff;
  }

  &:hover .ListItem__link-name {
    color: #ffffff;
  }

  &:hover .ListItem__icon {
    color: #ffffff;
  }
}

/* animation */
.ListItem__sublist--entering {
  height: 0;
}

.ListItem__sublist--entered {
  height: 80px;
}

.ListItem__sublist--exiting {
  height: 0;
}

.ListItem__sublist--exited {
  height: 0;
}
