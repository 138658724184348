@import '../../../Styles/Variables/varProject';

.CreateBadge {
  padding: 0.3125rem;
  flex: 1;

  .CreateBadge__label {
    display: block;
    font-weight: 400;
    color: #677788;
    margin-bottom: 0.5rem;
  }

  .CreateBadge__select {
    padding: 0.6125rem 1rem;
    border: 1px solid #eeeeee;
    border-radius: 6px;
    cursor: pointer;

    .CreateBadge__select__option {
      color: #1e2022;
    }

    .CreateBadge__select__icon {
      color: #677788;
      font-size: 20px;
    }
  }

  .CreateBadge__hour > .Field > .Field__label {
    display: block;
    font-weight: 400;
    color: #677788;
    margin-bottom: 0.5rem;
  }
}
