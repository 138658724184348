.NoteImage {
  margin: 0.5rem 0 1rem 0;

  &__box {
    display: flex;
    padding: 0.5rem;
    min-height: 180px;
    max-height: 240px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;

    .NoteImage__wrapperImage {
      position: relative;
      display: flex;
      flex: 1;
      background-color: rgba(225, 225, 225, 0.25);
      border-radius: 6px;

      &-label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        cursor: pointer;
      }

      &-input {
        height: 100%;
        width: 100%;
        display: none;
      }

      &-image {
        width: 40px;
      }

      &-text {
        margin-top: 0.5rem;
        font-size: 1rem;
        color: #1e2022;
        font-weight: 700;
      }

      &-text--subtitle {
        font-size: 0.75rem;
        color: #677788;
        font-weight: 400;
      }

      .NoteImage__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:hover,
      &:active {
        background-color: #c9c9c94d;
      }
    }
  }
}
