@import '../../Styles/Variables/varProject';

.TableLabels__content {
  padding: 0 1rem;
  border-bottom: 1px solid rgba($color: $color__text, $alpha: 0.5);
  background-color: rgba($color: #677788, $alpha: 0.05);
  flex-wrap: nowrap;

  .TableLabels__column {
    padding: 1rem;
    min-width: 160px;

    .TableLabels__text {
      font-size: 0.75rem;
      color: $color__title;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      white-space: nowrap;
    }
  }
}
