.Users__modal-body {
  width: 100%;
  padding-top: 1rem;
}

.Users__body-footer {
  display: flex;
  justify-content: flex-end;
  margin: 2rem -1rem 0 -1rem;
  padding-top: 0.5rem;
  border-top: 1px solid #eeeeee;
}

.Users_btn-delete {
  margin: 0 1rem;
  background-color: #d32f2f !important;
  border: 1px solid #d32f2f !important;
  padding: 0.625rem 1rem !important;
}

.Users__wrapper {
  overflow-x: scroll;

  .Users__table {
    width: 100%;
    border-collapse: collapse;
  }
}
