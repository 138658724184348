@import '../../../Styles/Variables/varProject';

.TypeAccount {
  display: flex;
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #eeeeee;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border: 1px solid $light__color__primary;
    box-shadow: 0 0px 0 4px rgba($color: $light__color__primary, $alpha: 0.2);
  }

  &::placeholder {
    color: #bdbdbd;
    font-size: 1rem;
    font-weight: 400;
  }

  .TypeAccount__checkbox {
    border: 1px solid #eeeeee;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    flex-shrink: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    transition: all 600ms ease-in;

    &:checked {
      border: none;
      background-color: rgba($color: $color__primary, $alpha: 0.7);
    }

    &:checked + .TypeAccount__icon {
      opacity: 1;
    }
  }

  .TypeAccount__label {
    color: #24292d;
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
  }

  .TypeAccount__icon {
    position: absolute;
    top: 2px;
    left: 2px;
    font-size: 16px;
    color: #ffffff;
    opacity: 0;
    pointer-events: none;
  }

  &:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    @media (max-width: 575px) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &:nth-child(2) {
    border-left: 0;
    border-radius: 0;

    @media (max-width: 575px) {
      border-top: 0;
      border-left: 1px solid #eeeeee;
    }
  }

  &:nth-child(3) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;

    @media (max-width: 575px) {
      border-top: 0;
      border-left: 1px solid #eeeeee;
    }
  }
}
