@import '../../Styles/Variables/varProject';

.Notification {
  position: fixed;
  width: 100%;
  max-width: 360px;
  bottom: 15px;
  left: 10px;
  z-index: 99999;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0 0.5rem 1rem rgba($color: $light__color__title, $alpha: 0.15);
  border-radius: 8px;
  transition: all 650ms;

  @media (max-width: 576px) {
    max-width: 100%;
    left: 0;
    bottom: 0;
    border-radius: 0;
  }
}

.Notification__content {
  display: flex;
  align-items: center;
  padding: 1.25rem;
}

.Notification__content-icon {
  width: 35px;
  height: 35px;
}

.Notification__content-title {
  font-size: 1rem;
  font-weight: 400;
  margin-left: 0.75rem;
  color: $light__color__title;
}

/* animation right */
.notificationRight--entering {
  right: -100%;
}

@media (max-width: 576px) {
  .notificationRight--entering {
    bottom: -100%;
  }
}

.notificationRight--entered {
  right: 20px;
}

@media (max-width: 576px) {
  .notificationRight--entered {
    right: 0;
    bottom: 0;
  }
}

.notificationRight--exiting {
  right: -100%;
}

.notificationRight--exited {
  right: -100%;
}

/* animation left */
.notificationLeft--entering {
  left: -100%;
}

@media (max-width: 576px) {
  .notificationLeft--entering {
    bottom: -100%;
  }
}

.notificationLeft--entered {
  left: 20px;
}

@media (max-width: 576px) {
  .notificationLeft--entered {
    left: 0;
    bottom: 0;
  }
}

.notificationLeft--exiting {
  left: -100%;
}

.notificationLeft--exited {
  left: -100%;
}
