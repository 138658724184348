@import '../../Styles/Variables/varProject';

.DashboardCard {
  display: flex;
  flex: 1;

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &-data {
    padding: 1.5rem 1.25rem;
  }

  &-introduction {
    background-color: rgba($color: $color__primary, $alpha: 0.15);
    border-radius: 6px;
  }

  &__title-data {
    font-size: 2rem;
    font-weight: bold;
    color: #1e2022;
    margin: 0 0 0.5rem 0;
  }

  &__subtitle-data {
    color: #677788;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  &__contentBtn {
    margin-top: auto;
  }

  &__title-introduction {
    font-size: 1rem;
    color: $color__primary;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  &__subtitle-introduction {
    color: $color__primary;
    font-weight: 400;
    font-size: 0.875rem;
  }

  &__content-introduction {
    padding: 1rem 0;
  }

  &__boxIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba($color: $color__primary, $alpha: 0.15);

    &-icon {
      color: $color__primary;
      font-size: 24px !important;
    }
  }

  &__btn {
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
  }

  &__image {
    align-self: end;
  }
}
