@import '../../Styles/Variables/varProject';

.HeaderTableVoucher {
  border-bottom: 1px solid rgba($color: $color__text, $alpha: 0.5);

  .HeaderTableVoucher__select {
    position: relative;
    padding: 0.6125rem 1rem;
    border: 1px solid #eeeeee;
    border-radius: 6px;
    cursor: pointer;
  }

  .HeaderTableVoucher__filter {
    @media (max-width: 768px) {
      margin-top: 8px;
    }
  }

  .HeaderTableVoucher__content {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 576px) {
      justify-content: flex-start;
    }
  }

  .HeaderTableVoucher__refresh {
    .HeaderTableVoucher__refresh__wrapper {
      display: flex;
      justify-content: flex-end;

      .HeaderTableVoucher__box {
        display: flex;
        align-items: center;
        border: 1px solid $color__primary;
        padding: 0.4375rem 0.65625rem;
        border-radius: 6px;
        cursor: pointer;
        transition: all 400ms ease-in;

        .HeaderTableVoucher__icon {
          font-size: 18px;
          color: $color__primary;
        }

        .HeaderTableVoucher__text {
          font-size: 0.875rem;
          color: $color__primary;
          margin-left: 6px;
        }

        &:hover {
          background-color: $color__primary;
        }

        &:hover > .HeaderTableVoucher__icon {
          color: #ffffff;
        }

        &:hover > .HeaderTableVoucher__text {
          color: #ffffff;
        }
      }

      @media (max-width: 576px) {
        justify-content: flex-start;
      }
    }

    @media (max-width: 768px) {
      margin-top: 8px;
    }
  }

  .filterBadge {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;

    .filterBadge__box {
      display: flex;
      align-items: center;
      padding: 0.5rem 0.875rem;
      border: 1px solid rgba($color: $color__text, $alpha: 0.25);
      border-radius: 6px;
      cursor: pointer;

      .filterBadge__icon {
        font-size: 18px;
        color: #677788;
      }

      .filterBadge__text {
        margin-left: 6px;
        font-size: 0.875rem;
        color: #677788;
      }

      &:hover {
        box-shadow: 0 3px 6px -2px rgba($color: $color__text, $alpha: 0.25);
      }

      &:hover > .filterBadge__icon {
        color: $color__primary;
      }

      &:hover > .filterBadge__text {
        color: $color__primary;
      }
    }

    .Dropdown__row {
      display: flex;
      flex-direction: column;
      margin-left: -5px;
      margin-right: -5px;
    }

    .Dropdown__col {
      padding: 0 5px;

      .Field > label {
        font-size: 0.875rem;
      }

      .Field > div > input {
        padding: 0.75rem 0.5rem;
      }
    }

    @media (max-width: 768px) {
      justify-content: flex-start;
    }
  }
}

.HeaderTableVoucher__box--upload {
  display: flex;
  align-items: center;
  border: 1px solid #4caf50;
  padding: 0.4375rem 0.65625rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 400ms ease-in;

  .HeaderTableVoucher__icon--upload {
    color: #4caf50;
    font-size: 18px;
  }

  .HeaderTableVoucher__text--upload {
    font-size: 0.875rem;
    margin-left: 6px;
    color: #4caf50;
  }

  &:hover {
    background-color: #4caf50;
  }

  &:hover > .HeaderTableVoucher__icon--upload {
    color: #ffffff;
  }

  &:hover > .HeaderTableVoucher__text--upload {
    color: #ffffff;
  }

  .small_date_range {
    font-size: 14px;
    padding: 12px;
  }
}
