@import '../../Styles/Variables/varProject';

.HeaderTableUsers {
  border-bottom: 1px solid rgba($color: $color__text, $alpha: 0.5);

  .HeaderTableUsers__select {
    position: relative;
    padding: 0.6125rem 1rem;
    border: 1px solid #eeeeee;
    border-radius: 6px;
    cursor: pointer;
  }

  .HeaderTableUsers__refresh {
    .HeaderTableUsers__refresh__wrapper {
      display: flex;
      justify-content: flex-end;

      .HeaderTableUsers__box {
        display: flex;
        align-items: center;
        border: 1px solid $color__primary;
        padding: 0.4375rem 0.65625rem;
        border-radius: 6px;
        cursor: pointer;
        transition: all 400ms ease-in;

        .HeaderTableUsers__icon {
          font-size: 18px;
          color: $color__primary;
        }

        .HeaderTableUsers__text {
          font-size: 0.875rem;
          color: $color__primary;
          margin-left: 6px;
        }

        &:hover {
          background-color: $color__primary;
        }

        &:hover > .HeaderTableUsers__icon {
          color: #ffffff;
        }

        &:hover > .HeaderTableUsers__text {
          color: #ffffff;
        }
      }

      @media (max-width: 576px) {
        justify-content: flex-start;
      }
    }

    @media (max-width: 768px) {
      margin-top: 8px;
    }
  }

  .HeaderTableUser__box--upload {
    display: flex;
    align-items: center;
    border: 1px solid #4caf50;
    padding: 0.4375rem 0.65625rem;
    border-radius: 6px;
    cursor: pointer;
    transition: all 400ms ease-in;

    .HeaderTableUser__icon--upload {
      color: #4caf50;
      font-size: 18px;
    }

    .HeaderTableUser__text--upload {
      font-size: 0.875rem;
      margin-left: 6px;
      color: #4caf50;
    }

    &:hover {
      background-color: #4caf50;
    }

    &:hover > .HeaderTableUser__icon--upload {
      color: #ffffff;
    }

    &:hover > .HeaderTableUser__text--upload {
      color: #ffffff;
    }
  }

  .HeaderTableUser__box--disableUsers {
    display: flex;
    align-items: center;
    border: 1px solid #eeeeee;
    padding: 0.4375rem 0.65625rem;
    border-radius: 6px;
    cursor: pointer;
    transition: all 400ms ease-in;

    .HeaderTableUser__text--disableUsers {
      font-size: 0.875rem;
      margin-left: 6px;
      color: #d32f2f;
    }
  }
}
